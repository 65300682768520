
const constants = {
    GOOGLE_OAUTH_CLIENT_KEY: "21257973516-es5lo4cl35n61vdjvddh3q5haab4og3r.apps.googleusercontent.com",
    DATE_FORMAT: 'HH:MM:ss - dd/mm/yyyy',
    // API_DOMAIN: 'http://localhost:4102',
    // API_DOMAIN: 'http://localhost:4102',
    API_DOMAIN: 'https://live-socket.hocmai.vn',
    // API_DOMAIN: 'https://live-socket.hocmaidev.tk',
    PUSH_NOTIFY_DOMAIN: 'https://apinotificationv2.hocmai.vn/api_hocmai_backend/notify/live_sv',
    IMAGE_DEFAULT_HEIGHT: 60,
    IMAGE_DEFAULT_WIDTH: 105,
    ALERT_TIME_OUT: 1000,
    TABLE_SCROLL_X: 1500,
    TABLE_SCROLL_Y: 300,
    MAXIMUM_LINE_LENGTH: 50,
    NUM_WORD_EACH_LINE: 10,
    LIVESTREAM_STATUS: {
        // livestream chưa phát, đang phát và đã phát
        COMING: 1,
        SHOWING: 2,
        CLOSED: 3,
        INVISIBLE: 4,
        ERROR: 0,
    },
    LIVESTREAM_METHOD: {
        RECORDED_VIDEO: 1,
        NEW_LIVE: 2,
    },
    PKGNAME_LIST: [
        // Tieu hoc
        // Android: 
        'com.hocmaiedu.primaryschool',
        // Ios:
        'com.hocmai.tieuhoc',

        // Hoc mai
        // Android: 
        'com.hocmai',
        // Ios: 
        'vn.hocmai.appuser'
    ],
    NOTIFICATION_TARGET: {
        ALL: 0,
        INDIVIDUAL: 1,
        GROUP: 2,
    },
}
export default constants
